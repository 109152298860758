<template>
  <LiefengContent :isBack="$route.query.name || $route.query.isLineHome || $route.query.page" @backClick="backClick">
    <template v-slot:title>实名认证
      <!-- <Button type="error" v-if="$route.query.name && !$route.query.isLineHome" @click="$router.push(`/${$route.query.name}`)">返回</Button>
      <Button type="error" v-if="$route.query.name && $route.query.isLineHome && $route.query.page" @click="$router.push(`/${$route.query.name}?isLineHome=true&page=true`)">返回</Button> -->
    <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarLeft>
    </template>
    <template v-slot:toolsbarRight>
      <Form  :label-colon="true" :inline="true" class="search">
             <FormItem>
                <Select
                 transfer
                  id="selectTxt"
                  v-model.trim="selectObj.model1" style="width:200px"
                  filterable
                  :remote-method="remoteMethod1"
                  :loading="loading1"
                  :clearable="true"
                  @on-select="changeSelect"
                  placeholder="按社区名称搜索"
              >
                <Option v-for="(item,index) in allCommunityList" :value="item.id" :key="index">
                  {{ item.fullName }}
                </Option>
              </Select>
             </FormItem>
             <FormItem>
               <Select
                  transfer
                  placeholder="请选择状态搜索"
                  v-model="selectObj.model2" style="width:200px;margin-left: 10px;"
              >
                <Option value="3">全部</Option>
                <Option value="0">待审核</Option>
                <Option value="1">已通过</Option>
              </Select>
             </FormItem>
              <FormItem>
                <Input
                  :maxlength="20"
                  placeholder='请输入姓名'
                  v-model.trim="selectObj.selectName"
                  style="width: 200px; margin-left: 10px"
                  @on-search="search"
              ></Input>
              </FormItem>
               <FormItem>
                  <Input
                  :maxlength="20"
                  placeholder='请输入手机号'
                  v-model.trim="selectObj.phone"
                  style="width: 200px; margin-left: 10px"
                  @on-search="search"
              ></Input>
               </FormItem>
               <FormItem>
                 <Select
                    transfer
                    placeholder="请选择状态"
                    v-model="selectObj.houseFlag" style="width:200px;margin-left: 10px;"
                >
                  <Option value="0">全部</Option>
                  <Option value="1">关联社区架构</Option>
                  <Option value="2">未关联社区架构</Option>
                </Select>
               </FormItem>
               <Button type="primary" icon="ios-search-outline" style="margin-right: 10px; " @click="search">搜索</Button>
               <Button type="success" icon="ios-refresh" style="margin-right: 10px;" @click="reset">重置</Button>
              <!-- </template> -->
              <Button type="info" style="margin-right: 10px;" @click="batchPassFn">批量通过</Button>
              <Button type="warning" icon="ios-cloud-upload-outline"
              style="margin-right: 10px" @click="importExcel">导入</Button>
      </Form>

      <!-- <div style="margin-left: 20px" class="header-wrapper">
        <template>
         
          
        </template>
        <template>
          
         
          
          
        
      </div> -->

    </template>
    <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
        <LiefengModal
          title="修改实名信息"
          :value="editModal.status"
          @input="editModalFn"
          :fullscreen="false"
          width="60%"
          class="edit"
        
        >
          <template v-slot:contentarea>
            <Edit :editRow="editModal.row" @changeCascader="changeCascader"></Edit>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="editModal.tip=true;editModalFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="editSave">保存</Button>
        </template>
      </LiefengModal>

      <!-- 导入excel弹窗 -->
      <LiefengModal title="导入" width="40%" :value="excelStatus" @input="excelModalData" class="export-modal">
        <template v-slot:contentarea>
          <Form :model="searchForm" :label-width="100">
            <FormItem>
               <span slot="label" class="validate">请选择社区:</span>
               <Tooltip placement="right-start"  style="width:100%">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="nodeId"
                  filterable
                  @on-change="getTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <!-- 防止报错，使用了index -->
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip>
            </FormItem>
          </Form>
           
          <LiefengUploadExcel style="margin:20px 0" :showDownModal="{isShow:true,downLink:'./excelfiles/verified.xlsx',downName:'实名认证模板'}" :headers="headers" :action="exportAction" :uploadData="uploadData" :isShow='showExcel' @success="excelSuccess"></LiefengUploadExcel>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/verified')
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengUploadExcel from './children/LiefengUploadExcel'
import Edit from './children/edit'


export default {
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
    Edit,
    LiefengUploadExcel
  },
  data() {
    return {
      //脱敏
      hasSensitive:true,
      //用户类型
      userTyle:[],
      //人员类型
      personalType: [],
      //证件类型
      credentials: 'CREDENTIALS_TYPE',
      credentialsList: [],
      //批量通过
      tableSelectDataId: [],
      //搜索
      selectObj: {
        model1: this.$route.query.id,
        model2: '0',
        selectName: '',
        phone:'',
        houseFlag:'0'
      },
      //seleced选中的社区
      options1: [],
      loading1: false,
      //社区
      allCommunityList: [],
      //社区名称
      communityList: [],
      //end
      tableSelectArr: [], //table选中的
      //table列表
      tableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
          fixed:'left'
        },
        {
          title: '姓名',
          key: 'name',
          minWidth: 100,
          align: "center"
        },
        {
          title: '区',
          key: 'zoneName',
          minWidth: 100,
          align: "center"
        },
        {
          title: '街道',
          key: 'streetName',
          minWidth: 100,
          align: "center"
        },
        {
          title: '手机号码',
          key: 'phone',
          align: "center",
          minWidth: 130
        },
        {
          title: '性别',
          key: 'sex',
          align: "center",
          minWidth: 80,
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  params.row.sex === '1' ? '男' :params.row.sex === '2'? '女':''
              )
            ])
          }
        },
        // {
        //   title: '用户类型',
        //   key: 'personalType',
        //   minWidth: 130,
        //   align: "center",
        //   render: (h, params) => {
        //     let arr3 = ''
        //     arr3 = this.userTyle.map(item => {
        //       return item.dictKey === params.row.personalType ? item.dictValue : ''
        //     })
        //     return h('div', [
        //       h('span', {},
        //           arr3)
        //     ])
        //   }
        // },
        {
          title: '证件类型',
          key: 'idType',
          minWidth: 130,
          align: "center",
          render: (h, params) => {
            let arr2 = ''
            arr2 = this.credentialsList.map(item => {
              return item.dictKey === params.row.idType ? item.dictValue : ''
            })
            return h('div', [
              h('span', {},
                  arr2)
            ])
          }
        },
        {
          title: '提交时间',
          key: 'birthday',
          minWidth: 170,
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  params.row.createTime ? this.$core.formatDate(new Date(params.row.createTime),'yyyy-MM-dd hh:mm:ss')  : ''
              )
            ])
          }
        },
        {
          title: '人员类型',
          key:'personalType',
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            let arr1 = ''
            arr1 = this.personalType.map(item => {
              return item.dictKey === params.row.personalType ? item.dictValue : ''
            })
            return h('div', [
              h('span', {},
                  arr1
              )
            ])
          }
        },
        {
          title: '社区名称',
          key: 'projectName',
          minWidth: 150,
          align: "center",
        },
        {
          title: '出生日期',
          key: 'birthday',
          minWidth: 170,
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  params.row.idNum ? params.row.birthday : ''
              )
            ])
          }
        },
        {
          title: '身份证号',
          key: 'idNum',
          minWidth: 200,
          align: "center",
        },
        {
          title: '详细地址',
          key: 'address',
          minWidth: 250,
          align: "center",
        },
        {
           title: '关联社区架构',
            key: 'status',
            fixed: 'right',
            width: 100,
            align: "center",
            render: (h, params) => {
              return h('div', [
                h('span', {
                  style:{
                    color:params.row.houseCode == '' || params.row.houseCode == null  ? 'red' :''
                  }
                },
                   params.row.houseCode && params.row.houseCode !== '' ? '是' :  '否' 
                )
              ])
            }
        },
        {
          title: '状态',
          key: 'status',
          fixed: 'right',
          width: 100,
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  params.row.status === '0' ? '待审核' :
                      params.row.status === '1' ? '审核通过' : '无'
              )
            ])
          }
        },
     
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 250,
          render: (h, params) => {
            let data = params.row
            return h('div', [
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small',
                },
                style: {
                  marginRight: '10px',
                  display:params.row.status === '1'?'none':'inline-block'
                },
                on: {
                  click: () => {
                      this.editModal.status = true
                      this.getDetail(params.row.id)
                  }
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small',
                },
                style: {
                  marginRight: '10px',
                  display:params.row.status === '1'?'none':'inline-block'
                },
                on: {
                  click: () => {
                    this.show(data)
                  }
                }
              }, '通过'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                style: {
                   display:params.row.status === '1'?'none':'inline-block'
                },
                on: {
                  click: () => {
                    this.down(data)
                  }
                }
              }, '驳回'),
            ]);
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量


      // 修改实名认证数据
      editModal:{
        status:false,
        row:{},
        tip:false,
        dataScopeList:[]
      },

      excelStatus:false,
      exportAction:'',
      uploadData:{
        orgCode:''
      },
      headers: {openId: window.sessionStorage.getItem('openId'),Authorization:window.sessionStorage.getItem('accessToken')},
      showExcel:0,
      nodeList: [],
      //异步加载社区
      loadingCommunity: false,
      nodeId:'',
      allNodeList:[]
    };
  },
 async created() {
    //获取所属社区列表
    let staffId = parent.vue.loginInfo.userinfo.id;
   await this.$get("/old/api/pc/project/selectStaffManagerProjectAndOrg", {
      staffId: staffId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then((res) => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.allCommunityList = JSON.parse(JSON.stringify(res.dataList));
        
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
   }).catch(err => {
     this.$Message.error({
       background: true,
       content: "获取数据失败"
     });
   });


   //字典接口
   await this.$get("/old/api/common/sys/findDictByDictType", {
     dictType: this.credentials,
   }).then((res) => {
     if (res.code == 200 && res.dataList && res.dataList.length > 0) {
       this.credentialsList = res.dataList
     } else {
       this.$Message.error({
         background: true,
         content: res.desc
       });
      }
    }).then(res => {
      this.$get("/old/api/common/sys/findDictByDictType", {
        dictType: 'PERSONAL_TYPE',
      }).then((res) => {
        if (res.code == 200 && res.dataList && res.dataList.length > 0) {
          this.personalType = res.dataList
        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      }).catch(err => {
        this.$Message.error({
          background: true,
          content: "获取数据失败"
        });
      });
   }).then(res => {
     this.$get("/old/api/common/sys/findDictByDictType", {
       dictType: 'PERSONAL_TYPE',
     }).then((res) => {
       if (res.code == 200 && res.dataList && res.dataList.length > 0) {
         this.userTyle = res.dataList
       } else {
         this.$Message.error({
           background: true,
           content: res.desc
         });
       }
     }).catch(err => {
       this.$Message.error({
         background: true,
         content: "获取数据失败"
       });
     });
   }).catch(err => {
     this.$Message.error({
       background: true,
       content: "获取数据失败"
     });
   });


    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });

    await this.getSelect()
  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },
  methods: {
    // 返回事件
    backClick(){
      if(this.$route.query.name && !this.$route.query.isLineHome) this.$router.push(`/${this.$route.query.name}`)
      if(this.$route.query.name && this.$route.query.isLineHome && this.$route.query.page) this.$router.push(`/${this.$route.query.name}?isLineHome=true&page=true`)
    },
    // 导入成功回调事件
    excelSuccess(){
      this.excelStatus = false
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    // 点击导入按钮
    importExcel(){
      this.nodeId = ''
      this.uploadData.orgCode = ''
      this.excelStatus = true
      this.showExcel = this.showExcel + 1
      this.exportAction =  window.vue.getProxy()["/old"].target + "/api/pc/userAuth/importExcelUserAuth";
    },
    //修改excel导入弹窗状态
    excelModalData(status) {
      this.excelStatus = status;
      // this.showExcel = false

    },
    //远程搜索方法
    remoteMethod1(query) {
      if (query !== '') {
        this.loading1 = true;
        setTimeout(() => {
          this.loading1 = false;
          const list = this.allCommunityList.map(item => {
            return {
              value: item.fullName,
              label: item.fullName
            };
          });
          this.options1 = list.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        }, 0);
      } else {
        this.options1 = [];
      }
    },


    tableSelect(data) { //表格选中
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
      this.tableSelectDataId = data
    },

    //选择器
    changeSelect(val){
    },


    //驳回
    down(data) {
      let statusData = {
        id: data.id,
        status: '2'
      }
      this.statusFn(statusData)
    },
    //批量通过
    batchPassFn() {
      if (this.tableSelectArr.length !== 0) {
        let arr = []
        this.tableSelectDataId.map((item, index) => {
          arr.push(item.id)
        })
        let statusData = {
          id: arr.join(),
          status: '1'
        }
        this.statusFn(statusData)
      } else {
        this.$Message.warning({
          background: true,
          content: "请勾选列表选项!",
          duration: 1
        });
      }
    },
    //审核通过
    show(data) {
      if (data.status === '1') {
        this.$Message.warning({
          background: true,
          content: "您已审核通过该选项,请勿重复操作!",
          duration: 1
        });
        return;
      } else {
        let statusData = {
          id: data.id,
          status: '1'
        }
        this.statusFn(statusData)
      }
    },
    statusFn(statusData) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>是否确定 <span style="color:red;margin: 0 2px;">${statusData.status === '1' ? '通过' : statusData.status === '2' ? '驳回' : ''}</span> 操作</p>`,
        cancelText: "取消",
        okText: "确认",
        onOk: () => {
          this.$post("/datamsg/api/pc/userAuth/updateStatus", {
            ...statusData
          }).then(res => {
            if (res.code == 200) {
              this.$Message.success({
                content: "操作成功",
                background: true
              });
              this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          })
              .catch(err => {
                this.loading = false;
                this.$Message.error({
                  background: true,
                  content: "获取数据失败"
                });
              });
        }
      });

    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/old/api/pc/userAuth/getDataList", {
        projectId:this.selectObj.model1,
        status:this.selectObj.model2==='3'?'':this.selectObj.model2,
        name:this.selectObj.selectName,
        phone:this.selectObj.phone,
        houseFlag:this.selectObj.houseFlag,
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        hasSensitive:this.hasSensitive
      }).then(res => {
        this.loading = false;
        if (res.code == 200 && res.dataList) {
          res.dataList.map(item => {

            item.createTime = this.$core.formatDate(
                new Date(item.createTime),
                "yyyy-MM-dd hh:mm:ss"
            );
            if (item.birthday) {
              item.birthday = this.$core.formatDate(
                  new Date(item.birthday),
                  "yyyy-MM-dd"
              );
            }else {
              item.birthday =''
            }


            if (item.status === '1') {  //禁用选择框
              item._disabled = true
            }
          });


          this.tableData = res.dataList;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;

        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      })
          .catch(err => {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: "获取数据失败"
            });
          });

    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    //重置
    reset() {
      this.selectObj = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },

    // zhw新增
    // 关闭模态框
    editModalFn(status){
      if(!status && this.editModal.tip){
        this.editModal.status = status
      }else{
        this.editModal.status = status
      }
    },
    // 编辑保存按钮
    editSave(){
      if(this.editModal.row.name == ''){
        this.$Message.warning({
            content:'姓名不能为空',
            background:true
        })
          return
      }
      if(this.editModal.row.personalType == '' || this.editModal.row.personalType == null){
        this.$Message.warning({
            content:'人员类型不能为空',
            background:true
        })
          return
      }
      if(this.editModal.row.idType == '' || this.editModal.row.idType == null){
        this.$Message.warning({
            content:'证件类型不能为空',
            background:true
        })
          return
      }
      if(this.editModal.row.idNum == ''){
        this.$Message.warning({
            content:'证件号码不能为空',
            background:true
        })
          return
        
      }
      let myreg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if(this.editModal.row.idNum && !myreg.test(this.editModal.row.idNum) && this.editModal.row.idType == '1'){
         this.$Message.warning({
              content:'证件号码错误',
              background:true
          }) 
          return
      }
      if(this.editModal.row.sex == ''){
        this.$Message.warning({
            content:'性别不能为空',
            background:true
        })
          return
      }
      if(this.editModal.row.birthday == ''){
        this.$Message.warning({
            content:'出生日期不能为空',
            background:true
        })
          return
      }
      // else{
      //   this.editModal.row.birthday = new Date(this.editModal.row.birthday).getTime()
      // }
      if(this.editModal.row.orgCode == null || this.editModal.row.orgName==null){
          this.$Message.warning({
            content:'请选择所属社区',
            background:true
          })
          return
      }
      if(this.editModal.row.orgCode == '' && this.editModal.row.orgName==''){
        
        if(this.editModal.dataScopeList.length == 0){
          this.$Message.warning({
            content:'请选择所属社区',
            background:true
          })
          return
        }else if(this.editModal.dataScopeList.length != 3){
          this.$Message.warning({
            content:'所属社区请选择到社区',
            background:true
          })
          return
        }else{
          this.editModal.row.orgName = this.editModal.dataScopeList[this.editModal.dataScopeList.length -1].label
          this.editModal.row.orgCode = this.editModal.dataScopeList[this.editModal.dataScopeList.length -1].value.split('-')
          this.editModal.row.orgCode = this.editModal.row.orgCode[this.editModal.row.orgCode.length -1]
        }
      }
       if(this.editModal.row.address == ''){
        this.$Message.warning({
            content:'现住地址不能为空',
            background:true
        })
          return
      }
       if(this.editModal.row.userType == ''){
        this.$Message.warning({
            content:'与住址关系不能为空',
            background:true
        })
          return
      }
      this.updateRow(this.editModal.row)
    },
    changeCascader(val){
      this.editModal.dataScopeList = val 
    },
    // 获取详情接口
    getDetail(data){
      this.$get('/old/api/pc/userAuth/selectByUserAuthId',{
        id:data
      }).then(res=>{
        if(res.code == 200){
          this.editModal.row = res.data
          this.oldAddr = res.data.address
          if(this.editModal.row.birthday && this.editModal.row.birthday!=''){
            this.editModal.row.birthday =  this.$core.formatDate(
                    new Date(this.editModal.row.birthday),
                    "yyyy-MM-dd"
                  )    
          }
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },
    updateRow(data){
      this.$post('/old/api/pc/userAuth/updateUserAuth',{
        ...data,
        birthday: new Date(this.editModal.row.birthday).getTime()
      },{"Context-Type":'application/json'}).then(res=>{
        if(res.code == 200){
          // this.selectObj = {};
          this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize
          });
          this.editModal.status = false
          this.editModal.tip = false
           this.$Message.success({
            content:'修改成功',
            background:true
          })
          return
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },
    //异步加载社区
    loadingCommunityFn(query) {
       if(query !== '' && !Number(query)) {
        this.loadingCommunity = true;
       this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
       if(this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if(Number(query)) {
        return;
      }
      else {
        this.nodeList = [];
      }
    },
    getSelect(){
       this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
          staffId: parent.vue.loginInfo.userinfo.id,
          orgCode:parent.vue.loginInfo.userinfo.orgCode
        })
          .then((res) => {
            if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
              // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
              this.nodeList = res.dataList.map((item) => {
                return {
                  value: item.code,
                  label: item.fullName,
                  projectCode: item.projectCode
                };
              });
              this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
              if(this.nodeList.length > 50) {
                this.nodeList.length = 50;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTree(val){
        this.nodeId = val
        this.uploadData.orgCode = this.nodeId
    }
   
  },
  
};
</script>

<style scoped lang='less'>

// /deep/ #toolsbarRight {
//   width: 1000%;

// }

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.edit {
  /deep/ #modal_contentarea{
        overflow: visible !important;
        padding-bottom: 20px;
  }
}
.export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>


